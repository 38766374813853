<template>
  <div class="text-left SignUpSignIn">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
          <router-link to="/login">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell Product Recommendation Quiz Logo"
              title="Product recommendation quiz"
              class="img-fluid"
              height="40"
              width="180"
            />
          </router-link>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 90%"
        >
          <div class="TitleDiv">
            <p class="title">Forgot your password?</p>
            <p class="SubTitle text-center">
              Don't worry. Enter your email. We'll send you a password reset
              link to reset your password.
            </p>
          </div>

          <!-- Inputs -->
          <div
            class="mt-5 w-100 d-flex flex-column align-items-center justify-content-center px-3 px-md-0"
          >
            <div class="NewFormInput  py-2 mx-auto">
              <input
                type="email"
                class="w-100"
                placeholder="Enter your email"
                v-model="userDetail.Email"
              />
            </div>

            <div class="mt-4 ">
              <button
                @click="SubmitResetPassword"
                :disabled="!isEmailValid"
                class="btn NewFormInput-Btn px-3 py-2"
              >
                <span v-if="!SubmitPasswordSpinner">Reset Password</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-none d-lg-block col-lg-5 objectSection lazyload"
        :data-bg="'~/assets/new-website/SignUpNewBg.svg'"
      >
        <LazyImage
          src="https://images.quizell.com/website/SignUpNewBg.svg"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Reset-Passwords",

  data() {
    return {
      userDetail: {
        Email: "",
      },
      SubmitPasswordSpinner: false,
    };
  },

  methods: {
    async SubmitResetPassword() {
      this.SubmitPasswordSpinner = true;
      try {
        let data = {
          email: this.userDetail.Email,
        };
        const response = await axios.post(`/password/email`, data);
        if (response.status == 200 && response.data.status == "fail") {
          this.$toasted.show("No Account found on this email ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else if (
          response.status == 200 &&
          response.data.status == "success"
        ) {
          this.$toasted.show("Reset Password link generated", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.userDetail.Email = "";
        }
      } catch (error) {
        if (error) {
          let { message } = error.response.data;
          if (message) {
            this.$toasted.show(message, {
              theme: "toasted-primary",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            });
          } else {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            });
          }
        }
        throw error;
      } finally {
        this.SubmitPasswordSpinner = false;
      }
    },
  },
  computed: {
    isEmailValid() {
      return this.userDetail.Email !== "";
    },
  },
};
</script>
<style>
.NewFormInput {
  border: 1px solid #dedede;
  border-radius: 12px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  padding: 14px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  max-width: 500px;
}

.NewFormInput input {
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  

}
.NewFormInput input:focus {
  border: none;
  outline: none;
}
.NewFormInput input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: start;

  color: #d1d1d1;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 300px;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
</style>
<style scoped>
/* @font-face {
  font-family: 'Gilroy'; 
  src: 
       url('~@/assets/fonts/Gilroy-Light.otf')  format('opentype');
 
  font-weight: normal;
  font-style: normal;
} */
.SignUpSignIn {
  overflow-x: hidden;
}

.SignUpSignIn .MainSection {
  padding: 1% 5%;
  /* height:100%; */
  min-height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
}

.MainSection .title {
  font-family: "Poppins";
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.82px;
  text-align: center;
  color: #161e34;
}
.MainSection .titleDesc h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #161e34;
  padding-bottom: 20px;
}
.objectSection {
  background-color: #f6edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 15px;
  }
}
</style>
